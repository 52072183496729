import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    posts: [],
    status: null,
    error: null
}

export const getHome = createAsyncThunk(
    "home/getHome",
    async function(_, {rejectWithValue}){
        const response = await axios.get("https://api.creativelab.am/api/home");

        if (response.status !== 200){
            throw new Error("Server Error")
        }

        return response.data
    }
)

const HomeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getHome.pending, state => {
                state.status = "Loading ..."
            })
            .addCase(getHome.fulfilled, (state, action)=>{
                state.status = "Success";
                state.posts = action.payload
            })
            .addCase(getHome.rejected, (state, action)=>{
                state.status = "Rejected";
                state.error = action.payload
            })
    }
})

export default HomeSlice.reducer;