import React from 'react';
import css from "./SecTwo.module.scss";
import {Link} from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {ABOUTUS} from "../../../Utils/Urls";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const SecTwo = () => {

    const {t} = useTranslation();

    const {posts} = useSelector(state => state.home)

  return (
      <section className={css.two}>
          <div className={css.twoMain}>
              <div className={css.text}>
                  <h3>{t("homeTwoAbout")}</h3>
                  <p>{t("homeTwoInfo")}</p>
              </div>
              <div className={css.img}>
                <img src={"https://api.creativelab.am/api/../" + posts?.home?.photo} alt="Loading..."/>
              </div>
              <Link to={ABOUTUS}>
                  {t("homeTwoAbout")} <ArrowOutwardIcon/>
              </Link>
          </div>
      </section>
  );
};

export default SecTwo;