import React from 'react';
import css from "./SecOne.module.scss";
import {portfolioNav} from "../../../Utils/portfolio/portfolioNav";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SecOne = () => {
    const pathSplit = useLocation().pathname.split("/");
    const {t} = useTranslation();
    return (
        <section className={css.one}>
            <div className={css.main}>
                <ul className={css.ul}>
                    {
                        portfolioNav.map(({id, path, name})=>{
                            return(
                                <li key={id} className={pathSplit[2] === path ? css.active : null}>
                                    <Link to={"/" + pathSplit[1] + "/" + path}>
                                        {t(name)}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </section>
    );
};

export default SecOne;