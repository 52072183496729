import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    workers: [],
    status: null,
    error: null
}

export const getWorkers = createAsyncThunk(
    "workers/getWorkers",
    async function(_, {rejectWithValue}){
        const response = await axios.get("https://api.creativelab.am/api/staff");

        if (response.status !== 200){
            throw new Error("Server Error")
        }

        return response.data.workers
    }
)

const WorkersSlice = createSlice({
    name: "workers",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getWorkers.pending, state => {
                state.status = "Loading ..."
            })
            .addCase(getWorkers.fulfilled, (state, action)=>{
                state.status = "Success";
                state.workers = action.payload
            })
            .addCase(getWorkers.rejected, (state, action)=>{
                state.status = "Rejected";
                state.error = action.payload
            })
    }
})

export default WorkersSlice.reducer;