import { constants } from "assets/constants";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import css from "./SecTwo.module.scss";

const SecTwo = () => {
    const pathSplit = useLocation().pathname.split("/")[2];

    const { t, i18n } = useTranslation();

    const posts = useSelector((state) => state.portfolio.portfolio);

    const arr = [t("portBranding"), t("portLogo"), t("portAdvertising"), t("portPhoto"), t("portPrinting"), t("portProduct"), t("web")];

    if (pathSplit === "all") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_all}</title>
                    <meta name="description" content={constants.descriptions.portfolio_all} />
                </Helmet>
                {posts?.map(({ id, image, translations, category_id }) => {
                    return (
                        <div key={id} className={css.productItem}>
                            <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                            <div className={css.hover}>
                                <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                <p className={css.title}>{arr[category_id - 1]}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else if (pathSplit === "branding") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_branding}</title>
                    <meta name="description" content={constants.descriptions.portfolio_branding} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "1")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    } else if (pathSplit === "logo") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_logo}</title>
                    <meta name="description" content={constants.descriptions.portfolio_logo} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "2")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    } else if (pathSplit === "advertising") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_advertising}</title>
                    <meta name="description" content={constants.descriptions.portfolio_advertising} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "3")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    } else if (pathSplit === "photo") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_photo}</title>
                    <meta name="description" content={constants.descriptions.portfolio_photo} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "4")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    } else if (pathSplit === "printing") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_printing}</title>
                    <meta name="description" content={constants.descriptions.portfolio_printing} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "5")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    } else if (pathSplit === "product") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.protfolio_product}</title>
                    <meta name="description" content={constants.descriptions.protfolio_product} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "6")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    } else if (pathSplit === "web") {
        return (
            <div className={css.portfolioPlace}>
                <Helmet>
                    <title>{constants.titles.portfolio_web}</title>
                    <meta name="description" content={constants.descriptions.portfolio_web} />
                </Helmet>
                {posts
                    ?.filter((items) => items.category_id == "7")
                    .map(({ id, image, translations, category_id }) => {
                        return (
                            <div key={id} className={css.productItem}>
                                <img src={"https://api.creativelab.am/api/../" + image} alt="Loading..." />
                                <div className={css.hover}>
                                    <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    <p className={css.title}>{arr[category_id - 1]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }
};

export default SecTwo;
