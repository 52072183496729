import css from "./App.module.scss";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
import Footer from "./Components/Footer/Footer";
import WhatsApp from "./Components/WhatsApp/WhatsApp";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import { getHome } from "./store/slices/homeSlice";
import { getAbout } from "./store/slices/aboutSlice";
import { getPartners } from "./store/slices/partnersSlice";
import { getWorkers } from "./store/slices/workersSlice";
import { getPortfolio } from "./store/slices/portfolioSlice";

function App() {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(getHome());
        dispatch(getAbout());
        dispatch(getPartners());
        dispatch(getWorkers());
        dispatch(getPortfolio());
    }, [dispatch]);

    return (
        <div className={css.App}>
            <Header />
            <Main />
            <WhatsApp />
            <Footer />
        </div>
    );
}

export default App;
