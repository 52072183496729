import React, {useEffect, useLayoutEffect, useState} from 'react';
import css from "./SecTwo.module.scss";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const SecTwo = () => {

    const {t} = useTranslation();

    const {posts} = useSelector(state => state.about);


    return (
        <section className={css.two}>
            <div className={css.countUp}>
                <div className={css.countMain}>
                    <VisibilitySensor>
                        {({ isVisible }) => (
                            <>
                                <div className={css.totalWorks}>
                                        <span>
                                            {isVisible ? <CountUp end={posts.business} duration={3}  className={css.count} /> : null}+
                                        </span>
                                    <p>{t("projects")}</p>
                                </div>
                                <div className={css.totalPartner}>
                                        <span>
                                            {isVisible ? <CountUp end={posts.partner} duration={3} className={css.count} /> : null}+
                                        </span>
                                    <p>{t("partner")}</p>
                                </div>
                                <div className={css.startWidth}>
                                        <span>
                                            {isVisible ? <CountUp end={posts.start} duration={3} className={css.count} /> : null}
                                        </span>
                                    <p>{t("startedAt")}</p>
                                </div>
                            </>
                        )}
                    </VisibilitySensor>
                </div>
            </div>
        </section>
    );
};

export default SecTwo;