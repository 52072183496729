import React from 'react';
import css from "./SecOne.module.scss";
import {useSelector} from "react-redux";

const SecOne = () => {

    const {posts} = useSelector(state => state.about);

    return (
        <section className={css.one}>
            <div className={css.main}>
                <img src={"https://api.creativelab.am/api/../" + posts?.main_image} alt="Loading..."/>
            </div>
        </section>
    );
};

export default SecOne;