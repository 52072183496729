import SouthEastIcon from '@mui/icons-material/SouthEast';

export const designTypes = [
    {id: Math.random(), type: "logoDesign", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "productDesign", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "expoDesign", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "packagingDesign", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "printingDesign", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "carBranding", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "advertisingBoardDesign", arrow: <SouthEastIcon/>},
]