import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    portfolio: [],
    status: null,
    error: null
}

export const getPortfolio = createAsyncThunk(
    "portfolio/getPortfolio",
    async function(_, {rejectWithValue}){
        const response = await axios.get("https://api.creativelab.am/api/portfolio");

        if (response.status !== 200){
            throw new Error("Server Error")
        }

        return response.data.portfolio
    }
)

const PortfolioSlice = createSlice({
    name: "portfolio",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPortfolio.pending, state => {
                state.status = "Loading ..."
            })
            .addCase(getPortfolio.fulfilled, (state, action)=>{
                state.status = "Success";
                state.portfolio = action.payload
            })
            .addCase(getPortfolio.rejected, (state, action)=>{
                state.status = "Rejected";
                state.error = action.payload
            })
    }
})

export default PortfolioSlice.reducer;