import React, {useEffect, useState} from 'react';
import css from "./SecFour.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/scss/navigation';

import { Navigation, Mousewheel, Keyboard } from "swiper";
import {useTranslation} from "react-i18next";

const SecFour = () => {

    const [width, setWidth]   = useState(window.innerWidth);
    const {t} = useTranslation();
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    return (
        <section className={css.four}>
            <div className={css.main}>
                <Swiper
                    cssMode={true}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    slidesPerView={width > 800 ? 3 : 1}
                    modules={[Navigation, Mousewheel, Keyboard]}
                    className={css.mySwiper}
                >
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servDesSwipeOneFunctionalLogo")}</p>
                            <ul>
                                <li>{t("servDesSwipeOneLi1")}</li>
                                <li>{t("servDesSwipeOneLi2")}</li>
                                <li>{t("servDesSwipeOneLi3")}</li>
                                <li>{t("servDesSwipeOneLi4")}</li>
                                <li>{t("servDesSwipeOneLi5")}</li>
                                <li>{t("servDesSwipeOneLi6")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servDesSwipeTwoExpoDesign")}</p>
                            <p className={css.subHeader}>{t("servDesSwipeTwoIdealExpoDesign")}</p>
                            <ul>
                                <li>{t("servDesSwipeTwoLi1")}</li>
                                <li>{t("servDesSwipeTwoLi2")}</li>
                                <li>{t("servDesSwipeTwoLi3")}</li>
                                <li>{t("servDesSwipeTwoLi4")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servDesSwipeThreeProductDesign")}</p>
                            <ul>
                                <li>{t("servDesSwipeThreeLi1")}</li>
                                <li>{t("servDesSwipeThreeLi2")}</li>
                                <li>{t("servDesSwipeThreeLi3")}</li>
                                <li>{t("servDesSwipeThreeLi4")}</li>
                                <li>{t("servDesSwipeThreeLi5")}</li>
                                <li>{t("servDesSwipeThreeLi6")}</li>
                                <li>{t("servDesSwipeThreeLi7")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servDesSwipeFourPrintingDesign")}</p>
                            <p className={css.subHeader}>{t("servDesSwipeFourPrintingDesignProf")}</p>
                            <ul>
                                <li>{t("servDesSwipeFourLi1")}</li>
                                <li>{t("servDesSwipeFourLi2")}</li>
                                <li>{t("servDesSwipeFourLi3")}</li>
                                <li>{t("servDesSwipeFourLi4")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servDesSwipeFiveCarBranding")}</p>
                            <ul>
                                <li>{t("servDesSwipeFiveLi")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
};

export default SecFour;