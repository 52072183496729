import React from 'react';
import css from "./SecOne.module.scss";
import {useSelector} from "react-redux";
const SecOne = () => {

    const {posts} = useSelector(state => state.home)

    return (
        <section className={css.video}>
            <video src={"https://api.creativelab.am/api/../" + posts?.home?.video} autoPlay loop muted={true} playsInline></video>
        </section>
    );
};

export default SecOne;