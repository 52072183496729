import React  from 'react';
import css from "./SecFour.module.scss";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";


const SecFour = () => {

    const {t} = useTranslation();

    const {posts} = useSelector(state => state.home)

  return (
    <section className={css.four}>
        <div className={css.main}>
          <div className={css.text}>
            <h3>{t("homeFourWorks")}</h3>
          </div>
          <div className={css.works}>
              <div className={css.small} style={{backgroundImage: `url("https://api.creativelab.am/api/../${posts?.home?.portfolio1}")` }}>
                    <div className={css.inside}>
                        <p>{t("branding")}</p>
                        <div className={css.line}> </div>
                    </div>
              </div>
              <div className={css.big} style={{backgroundImage: `url("https://api.creativelab.am/api/../${posts?.home?.portfolio2}")` }}>
                  <div className={css.inside}>
                      <p>{t("logoDesign")}</p>
                      <div className={css.line}> </div>
                  </div>
              </div>
              <div className={css.middle} style={{backgroundImage: `url("https://api.creativelab.am/api/../${posts?.home?.portfolio3}")`}}>
                  <div className={css.inside}>
                      <p>{t("printing")}</p>
                      <div className={css.line}> </div>
                  </div>
              </div>
              <div className={css.middle} style={{backgroundImage: `url("https://api.creativelab.am/api/../${posts?.home?.portfolio4}")` }}>
                  <div className={css.inside}>
                      <p>{t("photography")}</p>
                      <div className={css.line}> </div>
                  </div>
              </div>
              <div className={css.biggest} style={{backgroundImage: `url("https://api.creativelab.am/api/../${posts?.home?.portfolio5}")` }}>
                  <div className={css.inside}>
                      <p>{t("advertising")}</p>
                      <div className={css.line}> </div>
                  </div>
              </div>
          </div>
        </div>
    </section>
  );
};

export default SecFour;