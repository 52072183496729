import React from 'react';
import css from "./SecOne.module.scss";

const SecOne = () => {
    return (
        <section className={css.one}>
            <div className={css.oneMain}>

            </div>
        </section>
    );
};

export default SecOne;