

export const brandingInfo = [
    {id: Math.random(), number: "01", title: "researches", header: "researchesHeader", text: "researchesText"},
    {id: Math.random(), number: "02", title: "brandStrategy", header: "brandStrategyHeader", text: "brandStrategyText"},
    {id: Math.random(), number: "03", title: "storytelling", header: "storytellingHeader", text: "storytellingText"},
    {id: Math.random(), number: "04", title: "name", header: "nameHeader", text: "nameText"},
    {id: Math.random(), number: "05", title: "slogan", text: "sloganText"},
    {id: Math.random(), number: "06", title: "logo", header: "logoHeader", text: "logoText"},
    {id: Math.random(), number: "07", title: "visualisationOfTheBrandIdentity", header: "visualisationOfTheBrandIdentityHeader", text: "visualisationOfTheBrandIdentityText"},
]