import {ABOUTUS, CONTACTS, PARTNERS, PORTFOLIO, SERVICES} from "./Urls";
import Portfolio from "../Components/Portfolio/Portfolio";
import AboutUs from "../Components/AboutUs/AboutUs";
import Services from "../Components/Services/Services";
import Partners from "../Components/Partners/Partners";
import Contacts from "../Components/Contacts/Contacts";

export const routes = [
  {id: Math.random(), path: PORTFOLIO, name: "portfolio", element: <Portfolio/> },
  {id: Math.random(), path: ABOUTUS, name: "about", element: <AboutUs/> },
  {id: Math.random(), path: SERVICES, name: "services", element: <Services/> },
  {id: Math.random(), path: PARTNERS, name: "partners", element: <Partners/>},
  {id: Math.random(), path: CONTACTS, name: "contact", element:<Contacts/> },
]
