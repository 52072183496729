import React, { useEffect } from "react";
import css from "./Services.module.scss";
import SecOne from "./SecOne/SecOne";
import SecTwo from "./SecTwo/SecTwo";
import { constants } from "assets/constants";
import { Helmet } from "react-helmet";

const Services = () => {
    return (
        <div className={css.services}>
            <Helmet>
                <title>{constants.titles.services}</title>
                <meta name="description" content={constants.descriptions.services} />
            </Helmet>
            <SecOne />
            <SecTwo />
        </div>
    );
};

export default Services;
