import React, { useEffect } from "react";
import css from "./AboutUs.module.scss";
import SecOne from "./SecOne/SecOne";
import SecTwo from "./SecTwo/SecTwo";
import SecThree from "./SecThree/SecThree";
import SecFour from "./SecFour/SecFour";
import SecFive from "./SecFive/SecFive";
import { constants } from "assets/constants";
import { Helmet } from "react-helmet";

const AboutUs = () => {
    return (
        <div className={css.about}>
            <Helmet>
                <title>{constants.titles.about_us}</title>
                <meta name="description" content={constants.descriptions.about_us} />
            </Helmet>
            <SecOne />
            <SecTwo />
            <SecThree />
            <SecFour />
            <SecFive />
        </div>
    );
};

export default AboutUs;
