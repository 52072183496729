import React from 'react';
import css from './SecThree.module.scss';
import {Link} from "react-router-dom";
import {PORTFOLIO} from "../../../../Utils/Urls";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {useTranslation} from "react-i18next";

const SecThree = () => {

    const {t} = useTranslation();

    return (
        <section className={css.three}>
            <Link
                to={PORTFOLIO + "/advertising"}
            >
                {t("portfolio")} <ArrowOutwardIcon/>
            </Link>
        </section>
    );
};

export default SecThree;