import React from "react";
import css from "./Portfolio.module.scss";
import SecOne from "./SecOne/SecOne";
import SecTwo from "./SecTwo/SecTwo";

const Portfolio = () => {
    return (
        <div className={css.portfolio}>
            <SecOne />
            <SecTwo />
        </div>
    );
};

export default Portfolio;
