import React from 'react';
import css from "./SecThree.module.scss";
import {useTranslation} from "react-i18next";

const SecThree = () => {

    const {t} = useTranslation();

    return (
        <section className={css.three}>
            <div className={css.main}>
                <div className={css.up}>
                    <p>{t("aim")}</p>
                    <p>{t("consultation")}</p>
                    <p>{t("prototypeCreation")}</p>
                    <p>{t("options")}</p>
                    <p>{t("confirmation")}</p>
                </div>
                <div className={css.down}>
                    <p>1</p>
                    <span>------------------</span>
                    <p>2</p>
                    <span>--------------------</span>
                    <p>3</p>
                    <span>----------------------</span>
                    <p>4</p>
                    <span>-----------------</span>
                    <p>5</p>
                </div>
            </div>
        </section>
    );
};

export default SecThree;