import {ADVERTISING, BRANDING, DESIGN, DIGITAL, PHOTOVIDEO, PRINTING, WEB} from "../servicesUrls";
import {SERVICES} from "../Urls";


export const homeServices = [
    {id: Math.random(),path: SERVICES + "/" + BRANDING, name: "branding"},
    {id: Math.random(),path: SERVICES + "/" + DESIGN, name: "design"},
    {id: Math.random(),path: SERVICES + "/" + PRINTING, name: "printing"},
    {id: Math.random(),path: SERVICES + "/" + ADVERTISING, name: "advertising"},
    {id: Math.random(),path: SERVICES + "/" + DIGITAL, name: "digital"},
    {id: Math.random(),path: SERVICES + "/" + PHOTOVIDEO, name: "photo/video"},
    {id: Math.random(), path: SERVICES + "/" + WEB, name: "web"}
]