import React, {useState} from 'react';
import css from "./Header.module.scss";
import Logo from "../Logo/Logo";
import Nav from "../Nav/Nav";
const Header = () => {

    const [headerChange, setHeaderChange] = useState(false);

    const scrollDown = () => {
        if(window.scrollY > 0){
            setHeaderChange(true)
        }else{
            setHeaderChange(false)
        }
    }

    window.addEventListener("scroll", scrollDown)
    return (
        <header style={headerChange ? {background: "rgba(242, 242, 242, 0.9)", position: "fixed"} : {background: "transparent", position: "absolute"}}>
            <div className={css.main}>
                <Logo/>
                <Nav headerChange={headerChange}/>
            </div>
        </header>
    );
};

export default Header;