import React, { useEffect } from "react";
import css from "./Digital.module.scss";
import SecOne from "./SecOne/SecOne";
import SecTwo from "./SecTwo/SecTwo";
import SecThree from "./SecThree/SecThree";
import SecFour from "./SecFour/SecFour";
import { constants } from "assets/constants";
import { Helmet } from "react-helmet";

const Digital = () => {
    return (
        <div className={css.digital}>
            <Helmet>
                <title>{constants.titles.services_digital}</title>
                <meta name="description" content={constants.descriptions.services_digital} />
            </Helmet>
            <SecOne />
            <SecTwo />
            <SecThree />
            <SecFour />
        </div>
    );
};

export default Digital;
