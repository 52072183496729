import React from 'react';
import css from './SecOne.module.scss';
import gif from "../../../../Images/Services/Printing/tpagrutyun.gif";

const SecOne = () => {
    return (
        <section className={css.one}>
            <div className={css.main}>
                <div className={css.gif}>
                    <img src={gif} alt="Background"/>
                </div>
            </div>
        </section>
    );
};

export default SecOne;