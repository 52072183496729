import React from 'react';
import css from './SecTwo.module.scss';
import {Link} from "react-router-dom";
import {advertisingTypes} from "../../../../Utils/services/advertising/advertisingTypes";
import {useTranslation} from "react-i18next";

const SecTwo = () => {

    const {t} = useTranslation();

    return (
        <section className={css.two}>
            <div className={css.main}>
                <div className={css.text}>
                    <p>
                        {t("servAdsText")} <span>{t("servAdsSpan")}</span>
                    </p>
                </div>
                <div className={css.types}>
                    {
                        advertisingTypes.map(({id, type, arrow})=>{
                            return(
                                <Link
                                    key={id}
                                    className={css.type}
                                >
                                    <div className={css.arrow}>
                                        {arrow}
                                    </div>
                                    <p>
                                        {t(type)}
                                    </p>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
};

export default SecTwo;