import {ADVERTISING, BRANDING, DESIGN, DIGITAL, PHOTOVIDEO, PRINTING, WEB} from "./servicesUrls";
import Branding from "../Components/Services/Branding/Branding";
import Design from "../Components/Services/Design/Design";
import Printing from "../Components/Services/Printing/Printing";
import Advertising from "../Components/Services/Advertising/Advertising";
import Digital from "../Components/Services/Digital/Digital";
import PhotoVideo from "../Components/Services/PhotoVideo/PhotoVideo";
import WebDevelopment from "../Components/Services/WebDevelopment/WebDevelopment";


export const servicesService = [
    {id: Math.random(), number: "01", title: "branding", path: BRANDING, element: <Branding/>},
    {id: Math.random(), number: "02", title: "design", path: DESIGN, element: <Design/>},
    {id: Math.random(), number: "03", title: "printing", path: PRINTING, element: <Printing/>},
    {id: Math.random(), number: "04", title: "advertising", path: ADVERTISING, element: <Advertising/>},
    {id: Math.random(), number: "05", title: "digital", path: DIGITAL, element: <Digital/>},
    {id: Math.random(), number: "06", title: "photo/video", path: PHOTOVIDEO, element: <PhotoVideo/>},
    {id: Math.random(), number: "07", title: "web", path: WEB, element: <WebDevelopment/>}
]