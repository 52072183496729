
export const portfolioNav = [
    {id: Math.random(),path: "all", name: "all"},
    {id: Math.random(),path: "branding", name: "branding"},
    {id: Math.random(),path: "logo", name: "logoDesign"},
    {id: Math.random(),path: "product", name: "product"},
    {id: Math.random(),path: "advertising", name: "advertising"},
    {id: Math.random(),path: "photo", name: "photography"},
    {id: Math.random(),path: "printing", name: "printing"},
    {id: Math.random(),path: "web", name: "web"}
]