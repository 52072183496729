import React from 'react';
import css from "./SecThree.module.scss";
import {useTranslation} from "react-i18next";

const SecThree = () => {

    const {t} = useTranslation();

    return (
        <section className={css.three}>
            <div className={css.main}>
                <div className={css.up}>
                    <p>{t("servDigitalLi1")}</p>
                    <p>{t("servDigitalLi2")}</p>
                    <p>{t("servDigitalLi3")}</p>
                    <p>{t("servDigitalLi4")}</p>
                    <p>{t("servDigitalLi5")}</p>
                    <p>{t("servDigitalLi6")}</p>
                    <p>{t("servDigitalLi7")}</p>
                </div>
                <div className={css.down}>
                    <p>1</p>
                    <span>--------------------</span>
                    <p>2</p>
                    <span>--------------------</span>
                    <p>3</p>
                    <span>--------------------</span>
                    <p>4</p>
                    <span>--------------------</span>
                    <p>5</p>
                    <span>--------------------</span>
                    <p>6</p>
                    <span>--------------------</span>
                    <p>7</p>
                </div>
            </div>
        </section>
    );
};

export default SecThree;