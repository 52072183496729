import React from 'react';
import css from "./SecThree.module.scss";
import serviceBg from "../../../Videos/services.mp4";
import {homeServices} from "../../../Utils/Home/homeServices";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SecThree = () => {

  const {t} = useTranslation();

  return (
    <section className={css.three}>
      <div className={css.threeMain}>
        <div className={css.text}>
          <h3>{t("homeThreeServices")}</h3>
        </div>
        <div className={css.anime}>
          <div className={css.videoPlace}>
            <video src={serviceBg} autoPlay loop muted={true} playsInline></video>
          </div>
          <ul className={css.services}>
            {
              homeServices.map(({id, name, path})=>{
                return(
                    <li key={id}>
                      <Link to={path}>
                        {t(name)}
                      </Link>
                    </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SecThree;