import React from 'react';
import css from "./SecFour.module.scss";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {PORTFOLIO} from "../../../../Utils/Urls";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const SecFour = () => {

    const {t} = useTranslation();

    return (
        <section className={css.four}>
            <Link
                to={PORTFOLIO + "/web"}
            >
                {t("portfolio")}  <ArrowOutwardIcon/>
            </Link>
        </section>
    );
};

export default SecFour;