import {configureStore} from "@reduxjs/toolkit";
import PortfolioSlice from "./slices/portfolioSlice";
import HomeSlice from "./slices/homeSlice";
import AboutSlice from "./slices/aboutSlice";
import PartnersSlice from "./slices/partnersSlice";
import WorkersSlice from "./slices/workersSlice";

const store = configureStore({
    reducer: {
        home: HomeSlice,
        portfolio: PortfolioSlice,
        about: AboutSlice,
        workers: WorkersSlice,
        partners: PartnersSlice
    }
})

export default store