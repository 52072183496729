import React from 'react';
import css from "./SecTwo.module.scss";
import {servicesService} from "../../../Utils/servicesService";
import {Link} from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {useTranslation} from "react-i18next";

const SecTwo = () => {

    const {t} = useTranslation();

    return (
        <section className={css.two}>
            <div className={css.twoMain}>
                {
                    servicesService.map(({id, number, title, path})=>{
                        return(
                            <Link
                                key={id}
                                to={path}
                                className={css.service}
                            >
                                <p><span>{number}</span> {t(title)}</p>
                                <button className={css.btn} type="button">
                                    {t("seeMore")} <ArrowOutwardIcon/>
                                </button>
                            </Link>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default SecTwo;