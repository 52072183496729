import React from 'react';
import css from "./SecThree.module.scss";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const SecThree = () => {

    const {i18n} = useTranslation();

    const posts = useSelector(state => state.about.posts);

    return (
        <section className={css.three}>
            <div className={css.upText}>
                {
                    posts.translations !== undefined ?
                        <>
                            <h3>{i18n.language === "am" ? posts.translations[0].title_1 : posts.translations[1].title_1}</h3>
                            <p>{i18n.language === "am" ? posts?.translations[0].text_1 : posts.translations[1].text_1}</p>
                        </>
                    : null
                }

            </div>
            <div className={css.image}>
                <img src={"https://api.creativelab.am/api/../" + posts?.image} alt="Loading..."/>
            </div>
            <div className={css.downText}>
                <div className={css.downTextMain}>
                    {
                        posts.translations !== undefined ?
                            <>
                                <h3>{i18n.language === "am" ? posts?.translations[0].title_2 : posts?.translations[1].title_2}</h3>
                                <p>{i18n.language === "am" ? posts?.translations[0].text_2 : posts?.translations[1].text_2}</p>
                            </> : null
                    }
                </div>
            </div>
        </section>
    );
};

export default SecThree;