import React, { useEffect, useState } from "react";
import css from "./Contacts.module.scss";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { CONTACTS } from "../../Utils/Urls";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Success from "./Success/Success";
import Reject from "./Reject/Reject";
import { constants } from "assets/constants";
import { Helmet } from "react-helmet";

const Contacts = () => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted, isSubmitSuccessful },
    } = useForm({ mode: "onChange" });
    const onSubmit = async (data) => {
        try {
            const resp = await axios.post("https://api.creativelab.am/api/feedback", data);
        } catch (error) {
            setOpen(true);
        }
    };

    return (
        <div className={css.contacts}>
            <Helmet>
                <title>{constants.titles.contacts}</title>
                <meta name="description" content={constants.descriptions.contacts} />
            </Helmet>
            <div className={css.main}>
                <div className={css.text}>
                    <h3>{t("contact")}</h3>
                </div>
                {open ? (
                    <Reject />
                ) : isSubmitted && isSubmitSuccessful === true ? (
                    <Success />
                ) : (
                    <div className={css.formPlace}>
                        <h3>{t("contHeader")}</h3>
                        <form method="POST" className={css.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={css.inputs}>
                                <label htmlFor="firstname">
                                    <input type="text" placeholder={t("plName")} name={"firstname"} {...register("firstname", { required: true })} />
                                    {errors.firstname && <span>{t("errFirst")}</span>}
                                </label>
                                <label htmlFor="lastname">
                                    <input
                                        type="text"
                                        placeholder={t("plLastName")}
                                        name={"lastname"}
                                        {...register("lastname", { required: true })}
                                    />
                                    {errors.lastname && <span>{t("errLastName")}</span>}
                                </label>
                                <label htmlFor="phone">
                                    <input
                                        type="text"
                                        placeholder={t("plPhone")}
                                        name={"phone"}
                                        {...register("phone", {
                                            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/,
                                            required: true,
                                        })}
                                    />
                                    {errors.phone && <span>{t("errPhone")}</span>}
                                </label>
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        placeholder={t("plEmail")}
                                        name={"email"}
                                        {...register("email", { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, required: true })}
                                    />
                                    {errors.email && <span>{t("errEmail")}</span>}
                                </label>
                                <label htmlFor="text">
                                    <input type="text" placeholder={t("plAbout")} name={"text"} {...register("text", { required: true })} />
                                    {errors.text && <span>{t("errAbout")}</span>}
                                </label>
                            </div>
                            <div className={css.btns}>
                                <button type={"submit"}>
                                    {t("send")} <ArrowOutwardIcon />
                                </button>
                                <Link to={CONTACTS + "/join"}>{t("joinUs")}</Link>
                            </div>
                        </form>
                    </div>
                )}
                <div className={css.choose}>
                    <Link to={CONTACTS}>{t("contact")}</Link>
                    <Link to={CONTACTS + "/join"}>{t("joinUs")}</Link>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
