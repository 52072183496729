import React from "react";
import css from "./WhatsApp.module.scss";
import bg from "../../Images/WP/wp.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsApp = () => {
    return (
        <a href="https://wa.me/message/26B3WQBTIHWBL1" className={css.wp}>
            <img src={bg} />
            <WhatsAppIcon />
        </a>
    );
};

export default WhatsApp;
