export const constants = {
    titles: {
        home: "Elevate Your Brand with Expert Marketing, Branding, and Printing",
        portfolio_all: "Explore Our Portfolio: Discover Our Creations",
        portfolio_branding: "Branding Portfolio: Our Creative Identity Solutions",
        portfolio_logo: "Logo Design Portfolio: Our Distinctive Logos",
        protfolio_product: "Product Design Portfolio: Innovative Creations",
        portfolio_advertising: "Outdoor Advertising Portfolio: Real-world Examples",
        portfolio_photo: "Photography Portfolio: Capturing Moments",
        portfolio_printing: "Printing Portfolio: Business Solutions",
        portfolio_web: "Web Development Portfolio: Our Agency's Work",
        about_us: "About CreativeLab: Our Journey & Values",
        services: "Our Comprehensive Services: Creative Branding & Digital Marketing",
        services_branding: "Strategic Branding Services: Elevate Your Identity",
        services_design: "Graphic Design Excellence: Elevating Brand Identities",
        services_printing: "Printing Solutions: Quality Meets Creativity",
        services_advertising: "Outdoor Advertising: Digital Impact & Storytelling",
        services_digital: "Digital Marketing Services: Navigating the Demand",
        services_photo_video: "Visual Marketing: Capturing Brand Stories",
        services_web: "Web Development Services: Your Online Presence",
        partners: "Our Trusted Partners: Collaborating for Success",
        contacts: "Get in Touch: Contact CreativeLab Today",
        join: "Join Our Team: Career Opportunities at CreativeLab",
    },
    descriptions: {
        home: "CreativeLab: Your destination for impactful marketing, branding, and premium business printing solutions. Let's shape your brand's success.",
        portfolio_all:
            "Dive into our diverse portfolio of creative work. From branding to graphic design, our creations reflect innovation and excellence. Explore now.",
        portfolio_branding:
            "Explore our diverse branding portfolio. Witness our expertise in crafting unique brand identities that stand out in the market. Discover innovation and creativity.",
        portfolio_logo:
            "Dive into our logo design portfolio. Discover a gallery of unique logos that capture brands' essence and leave a lasting impression.",
        protfolio_product:
            "Explore our product design portfolio showcasing innovative creations that redefine functionality and aesthetics. Witness the future of design.",
        portfolio_advertising:
            "Discover our outdoor advertising portfolio with real-world examples of captivating campaigns. See how we make brands shine outdoors.",
        portfolio_photo:
            "Dive into our diverse photoshoot portfolio. From events to portraits, our lens preserves moments and tells stories. Explore our visual journey.",
        portfolio_printing:
            "Explore our printing portfolio for business. From marketing materials to branding, we provide the printed solutions that elevate your brand.",
        portfolio_web:
            "Discover our web development portfolio. As a trusted agency, we create websites that stand out, leaving a mark in the digital realm.",
        about_us: "Learn about our agency's mission, values, and the passionate team that drives us to bring creative visions to life.",
        services: "Explore our services, from branding to web development, including SMM and digital marketing. Transform your brand with us.",
        services_branding:
            "Our branding expertise spans naming, logo design, and more. We craft brands with a strategic edge, backed by market research and compelling storytelling.",
        services_design:
            "From logo and expo design to product and packaging, we blend creativity, experience, and modern trends for brand excellence.",
        services_printing:
            "Our printing services blend quality with creativity to bring your brand to life. From large format to digital prints, we've got you covered.",
        services_advertising:
            "Discover our outdoor advertising solutions, from billboards to LED displays. We bring your brand to life with digitization and compelling storytelling.",
        services_digital:
            "Our digital marketing expertise covers SMM, Influencer Marketing, PPC, Email marketing, and SEO. We help you navigate the digital landscape.",
        services_photo_video:
            "Elevate your brand with our photoshoot and video services. From food photography to brand documentaries, we bring your story to life.",
        services_web:
            "Explore our web development solutions, including corporate websites, UI/UX design, landing pages, online shops, and support. Your online success begins here.",
        partners: "Explore the dynamic partnerships that fuel our success. Together with our valued partners, we drive innovation and excellence.",
        contacts:
            "Reach out to us easily. Whether you have questions or need our services, our team is ready to assist. Contact us for creative solutions.",
        join: "Discover exciting career opportunities at Creativelab. We're expanding our team and looking for innovative talents like you to join us.",
    },
};
