import React from 'react';
import css from "./Logo.module.scss";
import logo from "../../Images/C-logo.png";
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <Link className={css.logo} to="home">
            <img src={logo} alt="Logo"/>
        </Link>
    );
};

export default Logo;