import React from 'react';
import css from "./Footer.module.scss";
import {ReactComponent as Insta} from "../../Images/insta.svg";
import {ReactComponent as Fb} from "../../Images/fb.svg";
import {ReactComponent as In} from "../../Images/in.svg";
import {ReactComponent as Be} from "../../Images/be.svg";
import {ReactComponent as Wp} from "../../Images/WP/whatssvg.svg";
import {ReactComponent as Tel} from "../../Images/tel.svg";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const {t} = useTranslation();

    return (
        <footer>
            <div className={css.infoPlace}>
                <p>{t("getInTouch")}</p>
                <p>{t("address")}</p>
                <p>+(374) 91 53-84-53</p>
                <p>{t("workHours")}</p>
            </div>
            <div className={css.socials}>
                <a href="https://www.instagram.com/creativelab__/" className={css.social}>
                    <Insta/>
                </a>
                <a href="https://www.facebook.com/creativelab19/" className={css.social}>
                    <Fb/>
                </a>
                <a href="https://www.linkedin.com/in/creative-lab-0775321b6/" className={css.social}>
                    <In/>
                </a>
                <a href="https://www.behance.net/creativagency1" className={css.social}>
                    <Be/>
                </a>
                <a href="https://wa.me/message/26B3WQBTIHWBL1" className={css.social}>
                    <Wp/>
                </a>
                <a href="https://t.me/realcreativelab" className={css.social}>
                    <Tel/>
                </a>
            </div>
        </footer>
    );
};

export default Footer;