import React, { useState, useEffect } from "react";
import css from "./Partners.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { constants } from "assets/constants";
import { Helmet } from "react-helmet";

const Partners = () => {
    const [isHovering, setIsHovering] = useState(null);

    const { t } = useTranslation();

    const handleMouseOver = (id) => {
        setIsHovering(id);
    };

    const handleMouseOut = () => {
        setIsHovering(null);
    };

    const { posts } = useSelector((state) => state.partners);

    return (
        <section className={css.partners}>
            <Helmet>
                <title>{constants.titles.partners}</title>
                <meta name="description" content={constants.descriptions.partners} />
            </Helmet>
            <div className={css.main}>
                <div className={css.text}>
                    <h3>{t("partners")}</h3>
                </div>
                <div className={css.allPartners}>
                    {posts?.map(({ id, image, imageHover, link }) => {
                        return (
                            <a href={link} key={id} className={css.partner} onMouseOver={() => handleMouseOver(id)} onMouseOut={handleMouseOut}>
                                <img
                                    src={
                                        isHovering === id
                                            ? "https://api.creativelab.am/api/../" + image
                                            : "https://api.creativelab.am/api/../" + imageHover
                                    }
                                    alt="Loading..."
                                />
                            </a>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Partners;
