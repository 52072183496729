import React, {useState} from 'react';
import {routes} from "../../Utils/Routes";
import {Link, useLocation} from "react-router-dom";
import css from "./Nav.module.scss";
import Languages from "../Languages/Languages";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'animate.css';
import logo from "../../Images/C-logo.png";
import {PORTFOLIO} from "../../Utils/Urls";
import {useTranslation} from "react-i18next";


const Nav = ({headerChange}) => {
    const pathSplit = useLocation().pathname.split("/");

    const [open, setOpen] = useState(false);

    const {t, i18} = useTranslation();

    return (
        <div className={css.navLang}>
            <nav className={css.nav}>
                <ul className={css.ul}>
                    {
                        routes.map(({id, name, path})=>{
                            return(
                                <li
                                    key={id}
                                >
                                    <Link
                                        to={path === PORTFOLIO ? PORTFOLIO + "/all" : path}
                                        style={pathSplit[1] === "home" && !headerChange ? {color: "#FFFFFF"} : {color: "#2A434E"}}
                                        className={path.includes(pathSplit[1]) ? css.active : null}
                                    >
                                        {t(`${name}`)}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </nav>
            <div className={css.forMenu}>
                <MenuIcon
                    onClick={()=>setOpen(true)}
                    style={pathSplit[1] === "home" && !headerChange ? {color: "#FFFFFF"} : {color: "#2A434E"}}
                />
                {
                    open ?
                            <nav>
                                <ul className="animate__animated animate__fadeInRight">
                                    <CloseIcon onClick={()=>setOpen(false)}/>
                                    <Link className={css.logo} to="home">
                                        <img src={logo} alt="Logo"/>
                                    </Link>
                                    {
                                        routes.map(({id, name, path})=>{
                                            return(
                                                <li
                                                    key={id}
                                                    onClick={()=>setOpen(false)}
                                                >
                                                    <Link
                                                        to={path === PORTFOLIO ? PORTFOLIO + "/all" : path}
                                                        style={{color: "#2A434E"}}
                                                        className={path.includes(pathSplit[1]) ? css.active : null}
                                                    >
                                                        {t(`${name}`)}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </nav>
                        : null
                }
            </div>
            <Languages headerChange={headerChange}/>
        </div>

    );
};

export default Nav;

