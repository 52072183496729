import React, {useEffect, useState} from 'react';
import css from "./SecThree.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/scss/navigation';
import {Keyboard, Mousewheel, Navigation} from "swiper";
import {useTranslation} from "react-i18next";

const SecThree = () => {

    const {t} = useTranslation();

    const [width, setWidth]   = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <section className={css.three}>
            <div className={css.main}>
                <Swiper
                    cssMode={true}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    slidesPerView={width > 800 ? 2 : 1}
                    modules={[Navigation, Mousewheel, Keyboard]}
                    className={css.mySwiper}
                >
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servPhotoSwipeOneHeader")}</p>
                            <ul>
                                <li>{t("servPhotoSwipeOneLi1")}</li>
                                <li>{t("servPhotoSwipeOneLi2")}</li>
                                <li>{t("servPhotoSwipeOneLi3")}</li>
                                <li>{t("servPhotoSwipeOneLi4")}</li>
                                <li>{t("servPhotoSwipeOneLi5")}</li>
                                <li>{t("servPhotoSwipeOneLi6")}</li>
                                <li>{t("servPhotoSwipeOneLi7")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servPhotoSwipeTwoHeader")}</p>
                            <ul>
                                <li>{t("servPhotoSwipeTwoLi1")}</li>
                                <li>{t("servPhotoSwipeTwoLi2")}</li>
                                <li>{t("servPhotoSwipeTwoLi3")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
};

export default SecThree;