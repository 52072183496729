import SouthEastIcon from '@mui/icons-material/SouthEast';

export const advertisingTypes = [
    {id: Math.random(), type: "billboards", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "lightboxes", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "glowingLetters", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "ledAdvertisingDisplays", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "transitAds", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "selfAdhesive", arrow: <SouthEastIcon/>},
    {id: Math.random(), type: "carBrandingLi", arrow: <SouthEastIcon/>},
]