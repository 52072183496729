import React from 'react';
import css from "./SecFour.module.scss";
import {aboutValues} from "../../../Utils/aboutValues";
import {useTranslation} from "react-i18next";

const SecFour = () => {

    const {t} = useTranslation();

    return (
        <section className={css.four}>
            <div className={css.text}>
                <p>{t("aboutFourValues")}</p>
            </div>
            <div className={css.info}>
                {
                    aboutValues.map(({id, logo, title, text})=>{
                        return(
                            <div
                                key={id}
                                className={css.values}
                            >
                                <img src={logo} alt="Loading..." className={css.image}/>
                                <p className={css.title}>{t(title)}</p>
                                <p className={css.textValue}>{t(text)}</p>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default SecFour;