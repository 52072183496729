import React from 'react';
import css from "./SecTwo.module.scss";
import {useTranslation} from "react-i18next";

const SecTwo = () => {

    const {t} = useTranslation();

    return (
        <section className={css.two}>
            <div className={css.main}>
                <div className={css.text}>
                    <p>
                        {t("servPhotoText")} <span>{t("servPhotoSpan")}</span>
                    </p>
                </div>
                <div className={css.info}>
                    <div className={css.up}>
                        <p>{t("servPhotoLi1")}</p>
                        <p>{t("servPhotoLi2")}</p>
                        <p>{t("servPhotoLi3")}</p>
                        <p>{t("servPhotoLi4")}</p>
                        <p>{t("servPhotoLi5")}</p>
                    </div>
                    <div className={css.down}>
                        <p>1</p>
                        <span>--------------------</span>
                        <p>2</p>
                        <span>--------------------</span>
                        <p>3</p>
                        <span>--------------------</span>
                        <p>4</p>
                        <span>--------------------</span>
                        <p>5</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecTwo;