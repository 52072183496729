import React, {useState} from 'react';
import css from "./SecFive.module.scss";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const SecFive = () => {

    const [isHovering, setIsHovering] = useState(null);

    const {t, i18n} = useTranslation();

    const handleMouseOver = (id) => {
        setIsHovering(id);
    };

    const handleMouseOut = () => {
        setIsHovering(null);
    };


    const {workers} = useSelector(state => state.workers)


    return (
        <section className={css.five}>
            <div className={css.fiveMain}>
                <div className={css.text}>
                    <h3>{t("aboutFourUs")}</h3>
                </div>
                <div className={css.workers}>
                    {
                        workers?.map(({id, image, hover_image, translations})=>{
                            return(
                                <div
                                    key={id}
                                    className={css.worker}
                                    onMouseOver={()=>handleMouseOver(id)}
                                    onMouseOut={handleMouseOut}
                                >
                                    <img src={isHovering === id ? "https://api.creativelab.am/api/../" + hover_image : "https://api.creativelab.am/api/../" + image} alt="Loading..."/>
                                    <div className={css.about}>
                                        <p className={css.position}>{i18n.language === "am" ? translations[0]?.profession : translations[1]?.profession}</p>
                                        <p className={css.name}>{i18n.language === "am" ? translations[0]?.name : translations[1]?.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
};

export default SecFive;