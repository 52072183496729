import React, { useEffect, useState } from "react";
import css from "./JoinUs.module.scss";
import { useForm } from "react-hook-form";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";
import { CONTACTS } from "../../../Utils/Urls";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Success from "../Success/Success";
import Reject from "../Reject/Reject";
import { constants } from "assets/constants";
import { Helmet } from "react-helmet";

const JoinUs = () => {
    const [selectedFile, setSelectedFile] = useState("");
    const [open, setOpen] = useState(false);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0].name);
    };

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted, isSubmitSuccessful, isSubmitting },
    } = useForm({ mode: "onChange" });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("firstname", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("phone", data.phone);
        formData.append("email", data.email);
        formData.append("position", data.position);
        try {
            await axios.post("https://api.creativelab.am/api/joinus", formData);
        } catch (error) {
            setOpen(true);
        }
    };

    return (
        <div className={css.contacts}>
            <Helmet>
                <title>{constants.titles.join}</title>
                <meta name="description" content={constants.descriptions.join} />
            </Helmet>
            <div className={css.main}>
                <div className={css.text}>
                    <h3>{t("joinUs")}</h3>
                </div>
                {open ? (
                    <Reject />
                ) : isSubmitted && isSubmitSuccessful === true ? (
                    <Success />
                ) : (
                    <div className={css.formPlace}>
                        <div className={css.header}>
                            <p>
                                <span>Creativelab</span>
                                {t("expands")}
                            </p>
                            <p className={css.subHeader}>{t("ifJoin")}</p>
                        </div>
                        <form method={"POST"} encType={"multipart/form-date"} className={css.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={css.inputs}>
                                <label htmlFor="firstname">
                                    <input type="text" placeholder={t("plName")} {...register("firstname", { required: true })} />
                                    {errors.firstname && <span>{t("errFirst")}</span>}
                                </label>
                                <label htmlFor="lastname">
                                    <input type="text" placeholder={t("plLastName")} {...register("lastname", { required: true })} />
                                    {errors.lastname && <span>{t("errLastName")}</span>}
                                </label>
                                <label htmlFor="phone">
                                    <input
                                        type="phone"
                                        placeholder={t("plPhone")}
                                        {...register("phone", {
                                            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/,
                                            required: true,
                                        })}
                                    />
                                    {errors.phone && <span>{t("errPhone")}</span>}
                                </label>
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        placeholder={t("plEmail")}
                                        {...register("email", { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, required: true })}
                                    />
                                    {errors.email && <span>{t("errEmail")}</span>}
                                </label>
                                <label htmlFor="position">
                                    <input type="text" placeholder={t("plPosition")} {...register("position", { required: true })} />
                                    {errors.position && <span>{t("errPosition")}</span>}
                                </label>
                                <label htmlFor="file">
                                    <input type="file" className={css.file} onInput={changeHandler} {...register("file", { required: true })} />
                                    <AttachFileIcon />
                                    <p>{selectedFile === "" ? t("attache") : selectedFile}</p>
                                    {errors.file && <span>{t("errCv")}</span>}
                                </label>
                            </div>
                            <div className={css.btns}>
                                <button type={"submit"} disabled={isSubmitting}>
                                    {t("send")} <ArrowOutwardIcon />
                                </button>
                                <p>{t("wfy")}</p>
                            </div>
                        </form>
                    </div>
                )}
                <div className={css.choose}>
                    <Link to={CONTACTS}>{t("contact")}</Link>
                    <Link to={CONTACTS + "/join"}>{t("joinUs")}</Link>
                </div>
            </div>
        </div>
    );
};

export default JoinUs;
