import React, {useState} from 'react';
import css from "./SecFive.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Parallax} from "swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import "swiper/scss/grid";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const SecFive = () => {
    const [isHovering, setIsHovering] = useState(null);

    const {t} = useTranslation();

    const handleMouseOver = (id) => {
        setIsHovering(id);
    };

    const handleMouseOut = () => {
        setIsHovering(null);
    };

    const count = useSelector(state => state.about.posts);
    const {posts} = useSelector(state => state.partners)

    return (
        <section className={css.five}>
            <div className={css.main}>
                <div className={css.text}>
                    <h3>{t("homeFivePartners")}</h3>
                </div>
                <div className={css.swiperPlace}>
                    {
                        posts.length === 0  ? null :
                        <Swiper
                            slidesPerView={10}
                            spaceBetween={20}
                            centeredSlides={false}
                            pagination={{
                                clickable: true,
                            }}
                            speed={1200}
                            navigation={false}
                            loop={true}
                            preloadImages={true}
                            updateOnImagesReady={true}
                            updateOnWindowResize={true}
                            autoplay={{
                                delay: 1,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Parallax]}
                            effect="slide"
                            className="mySwiper"
                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                            {
                                posts?.map(({id, image, imageHover})=>{
                                    return(
                                        <SwiperSlide onMouseOver={()=>handleMouseOver(id)} onMouseOut={handleMouseOut} key={id} className={css.swiperItem}>
                                            <img src={isHovering === id ? "https://api.creativelab.am/api/../" + image : "https://api.creativelab.am/api/../" + imageHover} alt="Logo" className={css.partners}/>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>

                    }


                </div>
                <div className={css.countUp}>
                    <div className={css.countMain}>
                        <VisibilitySensor>
                            {({ isVisible }) => (
                                <>
                                    <div className={css.totalWorks}>
                                        <span>
                                            {isVisible ? <CountUp end={count.business} duration={2}  className={css.count} /> : null}+
                                        </span>
                                        <p>{t("projects")}</p>
                                    </div>
                                    <div className={css.totalPartner}>
                                        <span>
                                            {isVisible ? <CountUp end={count.partner} duration={2} className={css.count} /> : null}+
                                        </span>
                                        <p>{t("partner")}</p>
                                    </div>
                                    <div className={css.startWidth}>
                                        <span>
                                            {isVisible ? <CountUp end={count.start} duration={2} className={css.count} /> : null}
                                        </span>
                                        <p>{t("startedAt")}</p>
                                    </div>
                                </>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default SecFive;