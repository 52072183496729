import React, {useState} from 'react';
import css from "./Languages.module.scss";
import {useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Languages = ({headerChange}) => {

    const [hy, setHy] = useState(false);
    const [en, setEn] = useState(true);

    const pathSplit = useLocation().pathname.split("/");

    const {t, i18n} = useTranslation();

    const changeLanguageEn = (language) => {
        i18n.changeLanguage(language);
        setHy(false);
        setEn(true);
    }

    const changeLanguageHy = (language) => {
        i18n.changeLanguage(language);
        setHy(true);
        setEn(false);
    }

    return (
        <div className={css.lang}>
            <button
                style={pathSplit[1] === "home" && !headerChange ? {color: "#ffffff"} : {color: "#2A434E"}}
                className={hy ? css.active : null}
                onClick={()=> changeLanguageHy("am")}
            >Հայ</button>
            <span style={pathSplit[1] === "home" && !headerChange ? {color: "#ffffff"} : {color: "#2A434E"}}>/</span>
            <button
                style={pathSplit[1] === "home" && !headerChange ? {color: "#ffffff"} : {color: "#2A434E"}}
                className={en ? css.active : null}
                onClick={()=> changeLanguageEn("en")}
            >Eng</button>
        </div>
    );
};

export default Languages;