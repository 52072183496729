import React from 'react';
import css from "./Reject.module.scss"
import {useTranslation} from "react-i18next";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Reject = () => {

    const {t} = useTranslation();

    return (
        <div className={css.reject}>
            <ErrorOutlineIcon/>
            <p>{t("reject")}</p>
        </div>
    );
};

export default Reject;