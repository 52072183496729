import React from 'react';
import css from "./Success.module.scss";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useTranslation} from "react-i18next";
const Success = () => {

    const {t} = useTranslation();

    return (
        <div className={css.success}>
            <CheckCircleOutlineIcon/>
            <p>{t("success")}</p>
        </div>
    );
};

export default Success;