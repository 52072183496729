import React from 'react';
import css from "./SecThree.module.scss";
import {Link} from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {brandingInfo} from "../../../../Utils/services/branding/brandingInfo";
import {PORTFOLIO} from "../../../../Utils/Urls";
import {BRANDING} from "../../../../Utils/servicesUrls";
import {useTranslation} from "react-i18next";

const SecThree = () => {

    const {t} = useTranslation();

    return (
        <section className={css.three}>
            <div className={css.infos}>
                {
                    brandingInfo.map(({id, number, title, header, text})=>{
                        return(
                            <div
                                key={id}
                                className={css.info}
                            >
                                <div className={css.number}>
                                    <span>{number}</span>
                                    <p className={css.title}>{t(title)}</p>
                                </div>
                                <div className={css.infoPlace}>
                                    <p className={css.header}>{t(header)}</p>
                                    <p className={css.text}>{t(text)}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Link to={PORTFOLIO + "/" + BRANDING} className={css.btn}>
                {t("portfolio")} <ArrowOutwardIcon/>
            </Link>
        </section>
    );
};

export default SecThree;