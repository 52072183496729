import val1 from "../Images/AboutValues/զարգացում.png";
import val2 from "../Images/AboutValues/թիմ.png";
import val3 from "../Images/AboutValues/համագործակցություն.png";
import val4 from "../Images/AboutValues/թափանցիկ.png";
import val5 from "../Images/AboutValues/համառություն.png";
import val6 from "../Images/AboutValues/պատասխանատվություն.png";

export const aboutValues = [
    {id: Math.random(), logo: val1, title: "aboutFourDevelopment", text: "aboutFourDevelopmentText"},
    {id: Math.random(), logo: val2, title: "aboutFourTeam", text: "aboutFourTeamText"},
    {id: Math.random(), logo: val3, title: "aboutFourCooperation", text: "aboutFourCooperationText"},
    {id: Math.random(), logo: val4, title: "aboutFourTransparent", text: "aboutFourTransparentText"},
    {id: Math.random(), logo: val5, title: "aboutFourPersistence", text: "aboutFourPersistenceText"},
    {id: Math.random(), logo: val6, title: "aboutFourResponsibility", text: "aboutFourResponsibilityText"}
]