import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    posts: [],
    status: null,
    error: null
}

export const getAbout = createAsyncThunk(
    "about/getAbout",
    async function(_, {rejectWithValue}){
        const response = await axios.get("https://api.creativelab.am/api/aboutus");

        if (response.status !== 200){
            throw new Error("Server Error")
        }

        return response.data.aboutus
    }
)

const AboutSlice = createSlice({
    name: "about",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAbout.pending, state => {
                state.status = "Loading ..."
            })
            .addCase(getAbout.fulfilled, (state, action)=>{
                state.status = "Success";
                state.posts = action.payload
            })
            .addCase(getAbout.rejected, (state, action)=>{
                state.status = "Rejected";
                state.error = action.payload
            })
    }
})

export default AboutSlice.reducer;