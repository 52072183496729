import React, {useEffect, useState} from 'react';
import css from "./SecFour.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/scss/navigation';

import { Navigation, Mousewheel, Keyboard } from "swiper";
import {useTranslation} from "react-i18next";

const SecFour = () => {

    const {t} = useTranslation();

    const [width, setWidth]   = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    return (
        <section className={css.four}>
            <div className={css.main}>
                <Swiper
                    cssMode={true}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    slidesPerView={width > 800 ? 3 : 1}
                    modules={[Navigation, Mousewheel, Keyboard]}
                    className={css.mySwiper}
                >
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("servPrintOneText")}</p>
                            <ul>
                                <li>{t("servPrintOneLi1")}</li>
                                <li>{t("servPrintOneLi2")}</li>
                                <li>{t("servPrintOneLi3")}</li>
                                <li>{t("servPrintOneLi4")}</li>
                                <li>{t("servPrintOneLi5")}</li>
                                <li>{t("servPrintOneLi6")}</li>
                                <li>{t("servPrintOneLi7")}</li>
                                <li>{t("servPrintOneLi8")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}> </p>
                            <ul>
                                <li>{t("servPrintTwoLi1")}</li>
                                <li>{t("servPrintTwoLi2")}</li>
                                <li>{t("servPrintTwoLi3")}</li>
                                <li>{t("servPrintTwoLi4")}</li>
                                <li>{t("servPrintTwoLi5")}</li>
                                <li>{t("servPrintTwoLi6")}</li>
                                <li>{t("servPrintTwoLi7")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}> </p>
                            <ul>
                                <li>{t("servPrintThreeLi1")}</li>
                                <li>{t("servPrintThreeLi2")}</li>
                                <li>{t("servPrintThreeLi3")}</li>
                                <li>{t("servPrintThreeLi4")}</li>
                                <li>{t("servPrintThreeLi5")}</li>
                                <li>{t("servPrintThreeLi6")}</li>
                                <li>{t("servPrintThreeLi7")}</li>
                                <li>{t("servPrintThreeLi8")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("laserCuttingEngraving")}</p>
                            <ul>
                                <li>{t("servPrintFourLi1")}</li>
                                <li>{t("servPrintFourLi2")}</li>
                                <li>{t("servPrintFourLi3")}</li>
                                <li>{t("servPrintFourLi4")}</li>
                                <li>{t("servPrintFourLi5")}</li>
                                <li>{t("servPrintFourLi6")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}>{t("advertisingBoards")}</p>
                            <ul>
                                <li>{t("servPrintFiveLi1")}</li>
                                <li>{t("servPrintFiveLi2")}</li>
                                <li>{t("servPrintFiveLi3")}</li>
                                <li>{t("servPrintFiveLi4")}</li>
                                <li>{t("servPrintFiveLi5")}</li>
                                <li>{t("servPrintFiveLi6")}</li>
                                <li>{t("servPrintFiveLi7")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={css.mySwipe}>
                        <div className={css.info}>
                            <p className={css.header}> </p>
                            <ul>
                                <li>{t("servPrintSixLi1")}</li>
                                <li>{t("servPrintSixLi2")}</li>
                                <li>{t("servPrintSixLi3")}</li>
                                <li>{t("servPrintSixLi4")}</li>
                                <li>{t("servPrintSixLi5")}</li>
                                <li>{t("servPrintSixLi6")}</li>
                                <li>{t("servPrintSixLi7")}</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
};

export default SecFour;